import { h } from 'preact';
import { withBreakPoints } from '../../hoc';
import { usePlayerContext, useStyles } from '../../hooks';
import { BACKGROUND, NEXT, RECOMMDATION_NEXT, USER_CLICK, USER_RECOMMENDATIONS_CLICKED } from '../../../types';
import { BADGE_STYLE, CSA_STYLE, DURATION_STYLE, ICON_STYLE, METADATA_STYLE, META_STYLE, PLAY_ICON_STYLE, PLAY_SVG_SIZE, RECO_IMAGE_STYLE, SEE_ALSO_STYLE, SUBTITLE_STYLE, SYNOPSIS_STYLE, TITLE_STYLE, WATCH_BUTTON_STYLE, WATCH_STYLE, WRAPPER_STYLE } from './styles';
import { publicPath } from '../../../configuration';
import { Icon } from '../common/svg/icons';

function RecommendationCard({
  image,
  badge,
  iconChannel,
  iconCsa,
  title,
  synopsis,
  subTitle,
  duration,
  index,
  metadata,
  small
}) {
  const setStyles = useStyles();
  const player = usePlayerContext();
  const clickHandler = (e) => {
    e.stopPropagation();
    player.trigger({ name: NEXT, payload: RECOMMDATION_NEXT, index });

    player.userEvents$.next({
      action: USER_RECOMMENDATIONS_CLICKED, source: USER_CLICK, value: index
    });
  };
  const defaultImagePath = `${publicPath}${BACKGROUND}`;
  const { width: playWidth, height: playHeight } = setStyles(PLAY_SVG_SIZE);
  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={() => {}}
      onClick={clickHandler}
      style={setStyles(WRAPPER_STYLE)}
    >
      <span style={setStyles(SEE_ALSO_STYLE)}>À voir aussi</span>
      <div style={{ ...setStyles(RECO_IMAGE_STYLE), backgroundImage: `url(${image || defaultImagePath})` }}>
        {badge && <span style={setStyles(BADGE_STYLE)}>{badge}</span>}
        <div style={{ ...ICON_STYLE, backgroundImage: `url(${iconChannel})` }} />
        <div style={{ ...CSA_STYLE, backgroundImage: `url(${iconCsa})` }} />
      </div>
      {small
        ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={setStyles(TITLE_STYLE)}>{title}</div>
            <div style={setStyles(METADATA_STYLE)}>{metadata}</div>
          </div>
        )
        : (
          <div>
            <div>
              <div style={setStyles(TITLE_STYLE)}>{title}</div>
              <div style={setStyles(SYNOPSIS_STYLE)}>{synopsis}</div>
            </div>
            <div style={setStyles(WATCH_BUTTON_STYLE)}>
              <div style={PLAY_ICON_STYLE}>
                <Icon name="play" height={playHeight} width={playWidth} />
              </div>
              <div style={META_STYLE}>
                <div style={setStyles(WATCH_STYLE)}>Regarder</div>
                <div style={setStyles(SUBTITLE_STYLE)}>{subTitle}</div>

                <div style={setStyles(DURATION_STYLE)}>{duration}</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default withBreakPoints(RecommendationCard);
