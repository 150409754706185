import { h } from 'preact';
import { connect } from '../../../ui/hoc';
import { LIVE_FILL_COLOR, OVERLAY_COLOR_OPAQUE, PRIMARY_FILL_COLOR, PRIMARY_TEXT_COLOR } from '../../../ui/theme/colors';
import TVLabel from '../common/label/TVLabel';

const TV_LIVESECTION_WRAPPER_STYLE = { position: 'absolute', paddingLeft: 80, paddingRight: 80, paddingTop: 40 };

function TVLiveSection({
  isAd,
  isLive,
  liveLabel,
  pubLabel,
  startOverTimeshifting,
  waitStart
}) {
  return waitStart ? null : (!pubLabel || !isAd) && isLive && (
    <div style={TV_LIVESECTION_WRAPPER_STYLE}>
      <TVLabel
        message={liveLabel}
        style={!startOverTimeshifting
          ? { backgroundColor: LIVE_FILL_COLOR, color: PRIMARY_TEXT_COLOR }
          : { backgroundColor: PRIMARY_FILL_COLOR, color: OVERLAY_COLOR_OPAQUE }}
      />
    </div>
  );
}

const selector = ({
  media: { isLive, isAd, timeshifting: { startOverTimeshifting } },
  ui: { liveLabel, pubLabel, waitStart }
}) => ({
  isAd,
  isLive,
  liveLabel,
  pubLabel,
  startOverTimeshifting,
  waitStart
});

export default connect(selector)(TVLiveSection);
