import { h } from 'preact';
import { connect } from './rxjs-connect';
import Error from '../components/error';
import { OverlayScreen } from '../components/common';
import { publicPath } from '../../configuration';
import { BACKGROUND } from '../../types';
import { OVERLAY_SCREEN_OPAQUE_BACKGROUND } from '../theme/colors';

const selector = ({
  error,
  media: { backgroundImage },
  ui: { isFullscreen, width, height }
}) => ({ error, isFullscreen, backgroundImage, width, height });
const withError = (Comp) => connect(selector)(({ error, isFullscreen, backgroundImage, width, height, ...p }) => (
  error.code ? (
    <div className="ftv-magneto--container-error">
      <OverlayScreen
        component={(props) => (
          <Error
            code={error.code}
            message={error.message}
            canRetry={error.canRetry}
            showCode={error.showCode}
            orangeErrorCode={error.orangeErrorCode}
            orangeUserCode={error.orangeUserCode}
            orangeMessage={error.orangeMessage}
            orangeTitle={error.orangeTitle}
            isFullscreen={isFullscreen}
            {...props}
          />
        )}
        style={{
          backgroundImage: `url(${publicPath}${BACKGROUND})`,
          transform: 'scale(1.009)',
          backgroundSize: width < height ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: OVERLAY_SCREEN_OPAQUE_BACKGROUND,
          backgroundPosition: 'center'
        }}
      />
    </div>
  ) : <Comp {...p} />
));

export default withError;
