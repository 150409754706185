import { h } from 'preact';
import { connect, withBreakPoints } from '../../hoc';
import { useStyles } from '../../hooks';

import { AdInfos, StartScreen } from '../info';
import SectionOverlay from './SectionOverlay';
import SectionTop from './SectionTop';
import SectionBottom from './SectionBottom';
import SectionCenter from './SectionCenter';
import SubtitleContainer from './SubtitleContainer';
import { UI_WRAPPER_STYLE, MAIN_WRAPPER_STYLE, MAIN_WRAPPER_SHOW, MAIN_WRAPPER_HIDE, MAIN_WRAPPER_PADDING } from './styles';
import Recommendations from './Recommendations';
import { MAIN_GRADIENT_AD, MAIN_GRADIENT_TV } from '../../theme/colors';
import { publicPath } from '../../../configuration';
import Zap from './Zap';

function Main({
  UIVisible, handleTap, /* passed via InteractionHandler */
  waitStart, forceHidden, hoverTimeline,
  isAd, isTv, isSeeking, mobile, canInteract, shouldDisplay,
  small, extraSmall, zappingEnabled, isFullscreen, isAudio,
  backgroundImage, width, height, panelLiveOption, isLive
}) {
  const setStyles = useStyles();

  const WRAPPER_STYLE = isAudio
    ? { ...UI_WRAPPER_STYLE,
      backgroundImage: `url(${backgroundImage.match(/background\.jpg/) ? `${publicPath}${backgroundImage}` : backgroundImage})`,
      backgroundSize: width < height ? 'contain' : 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transform: 'scale(1.009)'
    }
    : UI_WRAPPER_STYLE;
  return (
    <div style={{
      ...UI_WRAPPER_STYLE,
      flex: !panelLiveOption.show || (panelLiveOption.show && width >= height) ? 1 : `${(width / 16) * 9}px 0 0`
    }}
    >
      { shouldDisplay
        ? <Recommendations />
        : (
          <div style={WRAPPER_STYLE}>
            <SectionOverlay
              waitStart={waitStart}
              handleTap={handleTap}
              containerVisible={UIVisible}
            />
            <StartScreen isMounted={waitStart} />
            <AdInfos /* timeleft and info button for mobile */ />
            <div
              style={{
                ...UI_WRAPPER_STYLE,
                ...(UIVisible || waitStart ? {} : { cursor: 'none', opacity: 0 }),
                ...(isAd ? { cursor: 'pointer' } : {}),
                ...(isSeeking ? { backgroundColor: 'rgba(0, 0, 0, 0.6)' } : { backgroundColor: 'transparent' })
              }}
              role="presentation"
            >
              {
          !(mobile && forceHidden)
          && (
          <div
            className="ftv-magneto-main"
            style={({
              ...setStyles(MAIN_WRAPPER_STYLE),
              ...setStyles(MAIN_WRAPPER_PADDING),
              ...(UIVisible
                ? {
                  ...setStyles(MAIN_WRAPPER_SHOW),
                  ...isAd ? { backgroundImage: MAIN_GRADIENT_AD } : {},
                  ...isTv ? { backgroundImage: MAIN_GRADIENT_TV } : {}
                }
                : MAIN_WRAPPER_HIDE),
              ...(waitStart ? { opacity: 1 } : {})
            })}
          >
            <SectionTop
              hidden={
                (!canInteract && !waitStart)
                // Hide on xs,s in full screen when zapping enabled
                || ((isFullscreen) && zappingEnabled && (small || extraSmall))
              }
              containerVisible={UIVisible}
              isTv={isTv}
            />
            <SectionCenter hidden={waitStart || hoverTimeline || !UIVisible} />
            <SectionBottom hidden={waitStart || !canInteract || !UIVisible} />
            {(isLive && UIVisible && canInteract && !waitStart) && <Zap />}
          </div>
          )
        }
            </div>
            <SubtitleContainer />
          </div>
        )}
    </div>
  );
}

const selector = ({
  zapping: { list, zappingEnabled = list.length > 0 },
  media: { isAd, isTv, isAudio, backgroundImage, isLive },
  ui: { canInteract, forceHidden, hoverTimeline, isSeeking, waitStart, isFullscreen, width, height },
  recommendations: { shouldDisplay }
}) => ({
  shouldDisplay,
  waitStart, forceHidden, hoverTimeline,
  isAd, isTv, isSeeking, canInteract, zappingEnabled, isFullscreen,
  isAudio, backgroundImage, width, height, isLive
});

export default withBreakPoints(connect(selector)(Main));
