/**
 * @TODO Unit tests.
 * As we are waiting for npaw validation, changes may occur.
 * Test implementations are therefore delayed until then.
 */

import youbora from 'youboralib';
import { filter, switchMap, take, delay, map, withLatestFrom } from 'rxjs/operators';
import Quanteec from '@quanteec/quanteec-plugin/quanteec-youbora.min';
import { version, name, tech } from './manifest.json';
import { Network } from '../../utils';
import { YOUBORA_TRIGGER_START_SESSION } from './types';
import { ON_TOKEN_READY } from '../../core/media/types';
import { PLAY } from '../../core/command/types';

const playerVersion = global.MAGNETO_VERSION;
const quanteecAdapter = youbora.Adapter.extend(Quanteec.QuanteecYouboraAdapter);

youbora.adapters.AdapterFtvMagneto = quanteecAdapter.extend({
  /** Override to return current plugin version */
  getVersion() {
    return `${version}-${name}-${tech}`;
  },

  /** Override to return current playhead of the video */
  getPlayhead() {
    return this.player.getCurrentTime();
  },

  /** Override to return video duration */
  getDuration() {
    return this.player.select(({ media: { duration } }) => duration);
  },

  /** Override to return current bitrate */
  getBitrate() {
    return this.player.select(({ playback: { bitrate: { bandwidth } } }) => bandwidth);
  },

  /** Override to return user bandwidth throughput */
  getThroughput() {
    // Convert Megabits to bits.
    return this.currentBandwidth * 1048576 || 'UNKNOWN';
  },

  /** Override to return rendition */
  getRendition() {
    return this.player.select(({ playback: { bitrate: { bandwidth, width, height } } }) => (bandwidth && width && height) && `${width}x${height}@${bandwidth}`);
  },

  /** Override to return id */
  getTitle() {
    return this.player.select(({ media: { markers: { npaw: { title } } } }) => title);
  },

  /** Override to return title2 */
  getTitle2() {
    return this.player.select(({ media: { markers: { npaw: { program } } } }) => program);
  },

  /** Override to recurn true if live and false if VOD */
  getIsLive() {
    return this.player.select(({ media: { isLive } }) => isLive);
  },

  /** Override to return resource URL. */
  getResource() {
    return this.player.select(({ media: { resource } }) => resource);
  },

  /** Override to return player version */
  getPlayerVersion() {
    return playerVersion;
  },

  /** Override to return player's name */
  getPlayerName() {
    return 'magnetoscope';
  },

  /** Register listeners to this.player. */
  registerListeners() {
    const isStoreHydrated$ = this.player.medias$
      .pipe(delay(0)); // We want to run in the next event loop to ensure the store.dispatch associated with medias$ has been executed

    const shouldFireInit$ = isStoreHydrated$.pipe(
      withLatestFrom(this.player.playerConfig$),
      map(([, { autostart }]) => autostart)
    );
    const shouldAutostart$ = this.player.playerConfig$.pipe(
      map(({ autostart }) => autostart)
    );

    this.handleFireStart({ isStoreHydrated$, shouldAutostart$ });
    this.handleFireInit({ shouldFireInit$ });
    this.handleFireError();

    // Get user bandwith
    Network.connectionBandwidth()
      .subscribe((value) => { this.currentBandwidth = value; });

    // Register listeners
    this.references = ['play', 'pause', 'playing', 'seeking', 'seeked', 'buffering', 'waiting', 'buffered', 'ended', 'stopped', 'next', 'timeupdate']
      .reduce((acc, evt) => {
        if (this[`${evt}Listener`]) acc[evt] = this[`${evt}Listener`].bind(this);
        return acc;
      }, {});

    Object.keys(this.references).forEach((evt) => this.player.on(evt, this.references[evt]));
  },

  handleFireError() {
    this.stoppedErrorCodes = [];
    this.player.errors$.pipe(
      filter(({ error }) => error.fatal)
    )
      .subscribe(({ error }) => this.errorListener(error));
  },

  handleFireStart({ isStoreHydrated$, shouldAutostart$ }) {
    const waitPlay$ = this.player.commandController.stream$.pipe(
      filter(({ type }) => type === PLAY)
    );
    const waitToken$ = this.player.events$.pipe(
      filter(({ name: eventName }) => eventName === ON_TOKEN_READY)
    );

    const isNpawReady$ = shouldAutostart$.pipe(
      switchMap((autostart) => (autostart ? waitToken$ : waitPlay$))
    );

    isStoreHydrated$.pipe(switchMap(() => isNpawReady$))
      .subscribe(({ payload: url }) => {
        if (url) this.plugin.options.setOptions({ 'content.resource': url });
        this.fireStart();
      });

    /*
     When we need to start a new session on timeshifting streams, we will receive a new session event.
     We then wait for the first play after that.
     Sometimes, play is sent before the new session, to still call fireStart, we also listen to the playing event.
     This will send a shorter joinTime than the actual one but does not happen often
     */
    isStoreHydrated$
      .pipe(
        switchMap(() => this.player.events$.pipe(
          filter((eventName) => eventName === YOUBORA_TRIGGER_START_SESSION),
          switchMap(() => this.player.events$.pipe(
            filter((eventName) => ['play', 'playing'].includes(eventName)),
            take(1)
          ))
        ))
      )
      .subscribe(() => this.fireStart());
  },

  handleFireInit({ shouldFireInit$ }) {
    // We want to trigger the init event early when autostart is true
    shouldFireInit$.pipe(filter((isAutostart) => isAutostart))
      .subscribe(() => this.fireInit());
  },

  /** Unregister listeners to this.player. */
  unregisterListeners() {
    // Disable playhead monitoring
    if (this.monitor) this.monitor.stop();

    // Unregister listeners
    if (this.player && this.references) {
      Object.keys(this.references).forEach((evt) => {
        this.player.off(evt, this.references[evt]);
      });
      this.references = {};
    }
  },

  /** Listener for 'timeupdate' event. */
  timeupdateListener(/* event */) {
    if (this.getPlayhead() > 0.1) {
      this.fireJoin();
    }
  },

  /** Listener for 'pause' event. */
  pauseListener() {
    this.firePause();
  },

  /** Listener for 'playing' event. */
  playingListener(/* event */) {
    this.fireResume();
    this.fireSeekEnd();
    this.fireBufferEnd();
  },

  /** Listener for 'error' event. */
  errorListener({ code, description, stack }) {
    /* We send only one error of each type if the player is stopped.
       We don't call fireFatalError so that every error is in the
       same session.
    */
    if (!this.flags.isStarted) {
      if (!this.stoppedErrorCodes.includes(code)) {
        this.stoppedErrorCodes.push(code);
        this.fireError(code, description, stack);
      }

      return;
    }

    this.stoppedErrorCodes = [];
    this.fireFatalError(code, description, stack);
  },

  /** Listener for 'seeking' event. */
  seekingListener(/* event */) {
    this.fireSeekBegin();
  },

  /** Listener for 'seeked' event. */
  seekedListener(/* event */) {
    this.fireSeekEnd();
  },

  /** Listener for 'buffering' event. */
  bufferingListener(/* event */) {
    this.fireBufferBegin();
  },

  waitingListener(/* event */) {
    this.fireBufferBegin();
  },

  /** Listener for 'buffered' event. */
  bufferedListener(/* event */) {
    this.fireBufferEnd();
  },

  /** Listener for 'ended' event. */
  endedListener(/* event */) {
    this.fireStop();
  },

  /** Listener for 'stopped' event. */
  stoppedListener(/* event */) {
    this.fireStop();
  },

  nextListener(/* event */) {
    this.fireStop();
  }
});

export const Adapter = youbora.adapters.AdapterFtvMagneto;
