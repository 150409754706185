/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlaylistActiveIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask0_playlist_active" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="30" height="30">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.665 30.33C23.7643 30.33 30.33 23.7643 30.33 15.665C30.33 7.56574 23.7643 1 15.665 1C7.56574 1 1 7.56574 1 15.665C1 23.7643 7.56574 30.33 15.665 30.33ZM13.2549 7C12.2769 7 11.484 7.7973 11.484 8.78081V9.89673H10.624C9.64598 9.89673 8.85313 10.694 8.85313 11.6775V12.7935H7.77088C6.79285 12.7935 6 13.5908 6 14.5743V22.2192C6 23.2027 6.79285 24 7.77088 24H18.7451C19.7231 24 20.516 23.2027 20.516 22.2192V21.1033H21.5982C22.5763 21.1033 23.3691 20.306 23.3691 19.3225V18.2065H24.2291C25.2072 18.2065 26 17.4092 26 16.4257V8.78081C26 7.7973 25.2072 7 24.2291 7H13.2549ZM18.7451 12.7935H9.88139V11.6775C9.88139 11.2651 10.2139 10.9308 10.624 10.9308H21.5982C22.0084 10.9308 22.3409 11.2651 22.3409 11.6775V19.3225C22.3409 19.7349 22.0084 20.0692 21.5982 20.0692H20.516V14.5743C20.516 13.5908 19.7231 12.7935 18.7451 12.7935ZM23.3691 17.1725H24.2291C24.6393 17.1725 24.9717 16.8382 24.9717 16.4257V8.78081C24.9717 8.36837 24.6393 8.03402 24.2291 8.03402H13.2549C12.8448 8.03402 12.5123 8.36837 12.5123 8.78081V9.89673H21.5982C22.5763 9.89673 23.3691 10.694 23.3691 11.6775V17.1725ZM7.77088 13.8275C7.36074 13.8275 7.02825 14.1618 7.02825 14.5743V22.2192C7.02825 22.6316 7.36074 22.966 7.77088 22.966H18.7451C19.1552 22.966 19.4877 22.6316 19.4877 22.2192V14.5743C19.4877 14.1618 19.1552 13.8275 18.7451 13.8275H7.77088Z" fill="white" />
      </mask>
      <g mask="url(#mask0_playlist_active)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>

    </SVGWrapper>
  );
}

export default TvPlaylistActiveIcon;
