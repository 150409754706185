import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import Scrollable from '../../../ui/hoc/scrollable';
import Program from './Program';
import { PLAYLIST_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { RadioGroup } from '../../../ui/utils/RadioGroup';

function Programs({ activeTab, list, activeChannel, activeChannelId, currentTab, id }) {
  const { channels = [] } = list[activeTab] || {};
  useEffect(() => new RadioGroup([], 'audio-subtitles', true), []);

  return (
    <Scrollable forceInnerScroll>
      <Fragment>
        {channels.map((metadata) => (
          <Program
            metadata={metadata}
            active={[activeChannelId, id].includes(metadata.id)}
            activeTabMetadata={channels[activeChannel] || null}
            currentTab={currentTab}
          />
        ))}
      </Fragment>
    </Scrollable>
  );
}

const playlistToChannels = (playlist) => [
  {
    channels: playlist.map(({ src: id, config, cardInfo: { iconChannel, iconCsa, title, subTitle, image } }) => ({
      id,
      logo: iconChannel,
      image,
      title,
      preTitle: subTitle,
      iconCsa,
      config
    }))
  }
];

const selector = ({
  media: { duration, id },
  ui: {
    panelLiveOption: { currentTab }
  },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel, activeChannelId },
    metaOpened
  },
  playlist: { playlist }
}) => ({
  activeTab: currentTab === PLAYLIST_NAME ? 0 : activeTab, /** expecting  better handle on zapping ticket */
  activeChannel,
  metaOpened,
  currentTab,
  duration,
  list: currentTab === ZAPPING_NAME ? list : playlistToChannels(playlist),
  activeChannelId,
  id
});
export default connect(selector)(Programs);
