import { first, map, pipe, startWith, withLatestFrom, filter, switchMap, of, ReplaySubject, from, Subject, merge, interval, NEVER, BehaviorSubject, bufferCount, debounceTime } from 'rxjs';

import ShortcutController from '.';
import {
  BACK_BTN_SHORTCUTS,
  BUTTON_CLASS, KEYCODE_GENERICS_MAP,
  SELECT_SHORTCUT,
  SLIDER_CLASS, SLIDER_NAME, TV_OWN_KEYCODE_GENERICS_MAP,
  BTN_BACK_HTML_ID, BTN_RETRY_HTML_ID, BUTTON_NAME_PLAY, BUTTON_NAME_PAUSE, BUTTON_NAME_STOP,
  TV_REMOTE_SPEEDS,
  TV_REMOTE_SPEED_CODES,
  TV_FORWARD,
  TV_SPEED_INTERVAL,
  TV_SPEED_JUMP,
  TV_DEFAULT_SPEED,
  BUTTON_NAME_RESUME_PAUSEROLL,
  SELECTOR_PAUSEROLL,
  UP_ARROW_KEYCODE,
  DOWN_ARROW_KEYCODE,
  LEFT_ARROW_KEYCODE,
  RIGHT_ARROW_KEYCODE,
  TV_INTERACTION_ICON_DURATION,
  ERROR_CONTAINER_CLASS } from './types';
import { AD_PAUSE, PLAYER_CLOSE, UI_FAST_SPEED, UI_FORCE_VISIBILITY, UI_IS_INTERACTING, UI_VISIBLE } from '../../store/types';
import getElementByNamesAsync from '../../ui/utils/getElementByNamesAsync';
import { PAUSE, PLAY } from '../command/types';
import { USER_CLICK, USER_PLAY } from '../../types';

export class TVShortcutController extends ShortcutController {
  constructor({ player }) {
    super({ player });
    this.handleArrows$ = new ReplaySubject(1);
    this.currentSpeed$ = new BehaviorSubject(1);
    this.uiVisible$ = new Subject();
    this.uiBlocked$ = new Subject();
    this.hideFastSpeedTimer$ = null;

    this.refHandleKeyEvent = TVShortcutController.handleKeyEvent.bind(this, player, this.handleArrows$);
    this.refHandleErrorsKeys = TVShortcutController.handleErrorsKeys.bind(this, player);
    this.refErrors = player.errors$.pipe(first()).subscribe(() => {
      window.addEventListener('keydown', this.refHandleErrorsKeys);
    });
    window.addEventListener('keydown', this.refHandleKeyEvent);

    this.handleArrows$
      .pipe(TVShortcutController.handleKeys(player, this.uiVisible$))
      .subscribe(TVShortcutController.focusElement);

    TVShortcutController.createUiVisible({ events$: player.events$ })
      .subscribe(this.uiVisible$);
    TVShortcutController.createUIBlockStream({ player, uiVisible$: this.uiVisible$ })
      .subscribe(this.uiBlocked$);

    this.uiBlocked$.subscribe((isInteracting) => player.store.dispatch({
      type: UI_IS_INTERACTING,
      payload: { isInteracting }
    }));

    TVShortcutController.createGetFocusElementStream({ uiVisible$: this.uiVisible$, events$: player.events$, uiBlocked$: this.uiBlocked$ })
      .subscribe(TVShortcutController.focusElement);
    TVShortcutController.createCurrentSpeedStream({ handleArrows$: this.handleArrows$, currentSpeed$: this.currentSpeed$ })
      .subscribe(this.currentSpeed$);

    this.createFastSpeedStream(player, this.currentSpeed$)
      .subscribe(player.seek.bind(player));

    TVShortcutController.createNormalSpeedStream(this.handleArrows$, player.events$, this.currentSpeed$)
      .subscribe(this.currentSpeed$);
  }

  static focusElement = (elt) => elt?.focus();

  countSpeedInteractionIconHide(player, currentSpeed) {
    this.hideFastSpeedTimer = setTimeout(() => {
      player.store.dispatch({ type: UI_FAST_SPEED, payload: { currentSpeed, fastSpeed: false } });
    }, TV_INTERACTION_ICON_DURATION);
  }

  /**
   *
   * stream the default speed : come back to normal speed => TV_DEFAULT_SPEED
   */

  static createNormalSpeedStream(handleArrows$, events$, currentSpeed$) {
    return merge(
      currentSpeed$.pipe(
        filter((currentSpeed) => currentSpeed !== 1),
        switchMap(() => events$.pipe(filter((e) => e === PLAY)))
      ),
      handleArrows$.pipe(filter((handleArrows) => TVShortcutController
        .getPrioritizedKeyCode(handleArrows, KEYCODE_GENERICS_MAP) === 32))
    ).pipe(
      debounceTime(50),
      map(() => TV_DEFAULT_SPEED)
    );
  }

  /**
   *
   * stream the next time to seek
   */

  createFastSpeedStream(player, currentSpeed$) {
    const { currentTime$, duration$ } = player.rendererController;
    return currentSpeed$.pipe(
      bufferCount(2, 1),
      switchMap(([previousSpeed, currentSpeed]) => {
        clearTimeout(this.hideFastSpeedTimer);
        if (currentSpeed === TV_DEFAULT_SPEED) {
          if (previousSpeed !== currentSpeed) {
            player.play({ userGesture: true });
            player.userEvents$.next({ action: USER_PLAY, source: USER_CLICK });
            player.store.dispatch({ type: UI_FAST_SPEED, payload: { currentSpeed, fastSpeed: true } });
            this.countSpeedInteractionIconHide(player, currentSpeed);
          } else {
            this.countSpeedInteractionIconHide(player, currentSpeed);
          }

          return NEVER;
        }
        player.pause();
        return interval(TV_SPEED_INTERVAL).pipe(
          withLatestFrom(currentTime$, duration$),
          filter(([, currentTime, duration]) => {
            const seekTo = currentTime + currentSpeed * TV_SPEED_JUMP;
            const shouldSeek = seekTo > 0 && seekTo < duration;
            player.store.dispatch({ type: UI_FAST_SPEED, payload: { currentSpeed, fastSpeed: shouldSeek } });
            return shouldSeek;
          }),
          map(([, currentTime]) => currentTime + currentSpeed * TV_SPEED_JUMP)
        );
      })
    );
  }

  static createUIBlockStream({ player, uiVisible$ }) {
    return uiVisible$.pipe(
      map(() => {
        if (!player.store) return false;

        const {
          ui: {
            panelLiveOption: { show: panelOpened }
          }
        } = player.store.getState();
        return panelOpened;
      })
    );
  }

  static createGetFocusElementStream({ uiVisible$, events$, uiBlocked$ }) {
    return merge(
      uiVisible$,
      events$.pipe(filter((name) => [PAUSE, AD_PAUSE].includes(name)))
    ).pipe(
      withLatestFrom(uiBlocked$),
      switchMap(([, uiBlocked]) => (!uiBlocked
        ? from(
          getElementByNamesAsync([
            BUTTON_NAME_PLAY,
            BUTTON_NAME_PAUSE,
            BUTTON_NAME_STOP
          ])
        )
        : NEVER))
    );
  }

  static getPrioritizedKeyCode = (
    { keyIdentifier, key, keyCode },
    mapping = TV_OWN_KEYCODE_GENERICS_MAP
  ) => TVShortcutController.getMappedKeyCode(mapping, keyIdentifier)
        || TVShortcutController.getMappedKeyCode(mapping, key)
        || TVShortcutController.getMappedKeyCode(mapping, keyCode);

  static getPrioritizedEventKey = (
    { keyIdentifier, key, keyCode },
    mapping = TV_OWN_KEYCODE_GENERICS_MAP
  ) => {
    if (TVShortcutController.getMappedKeyCode(mapping, keyIdentifier)) {
      return keyIdentifier;
    }

    if (TVShortcutController.getMappedKeyCode(mapping, key)) {
      return key;
    }

    if (TVShortcutController.getMappedKeyCode(mapping, keyCode)) {
      return keyCode;
    }

    return keyIdentifier || key || keyCode;
  };

  static handleKeys(player, uiVisible$) {
    return pipe(
      filter(({ keyIdentifier, key, keyCode }) => TVShortcutController.getPrioritizedKeyCode({ keyIdentifier, key, keyCode })),
      map(({ keyIdentifier, key, keyCode }) => TVShortcutController.getPrioritizedKeyCode({ keyIdentifier, key, keyCode })),
      withLatestFrom(uiVisible$),
      switchMap(([priorKey, uiVisible]) => {
        if (!uiVisible && !document.getElementsByClassName(ERROR_CONTAINER_CLASS).length) {
          player.store.dispatch({ type: UI_FORCE_VISIBILITY, payload: { forceVisible: true } });
          setTimeout(() => {
            player.store.dispatch({ type: UI_FORCE_VISIBILITY, payload: { forceVisible: false } });
          }, 0);

          return from(getElementByNamesAsync([BUTTON_NAME_PLAY, BUTTON_NAME_PAUSE, BUTTON_NAME_STOP]));
        }
        return of(TVShortcutController.handleArrows(player, priorKey));
      })
    );
  }

  static handleErrorsKeys(player, { keyIdentifier, keyCode, key }) {
    if (BACK_BTN_SHORTCUTS.includes(keyIdentifier) || BACK_BTN_SHORTCUTS.includes(key) || BACK_BTN_SHORTCUTS.includes(keyCode)) {
      if ([BTN_BACK_HTML_ID, BTN_RETRY_HTML_ID].includes(document.activeElement.id)) {
        player.events$.next(PLAYER_CLOSE);
      }
    } else if (TVShortcutController
      .getPrioritizedKeyCode({ keyIdentifier, key, keyCode }, KEYCODE_GENERICS_MAP) === SELECT_SHORTCUT) { // back is pressed
      if (document.activeElement.id === BTN_RETRY_HTML_ID) { // retry is focused
        player.retry();
      }
    }
  }

  static getMappedKeyCode = (mapping, shortcut) => mapping
    .find(({ key }) => key.includes(shortcut))
    ?.mapTo;

  static handleKeyEvent(player, handleArrows$, e) {
    handleArrows$.next(e);
    const { key, keyIdentifier, keyCode, shiftKey } = e;

    player.handleKeyEvent(
      TVShortcutController.getPrioritizedEventKey(
        { keyIdentifier, key, keyCode },
        [...KEYCODE_GENERICS_MAP, ...TV_OWN_KEYCODE_GENERICS_MAP]
      ),
      { shiftKey }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  mapKeyCode() {
    return pipe(
      map((shortcut) => TVShortcutController.getMappedKeyCode(KEYCODE_GENERICS_MAP, shortcut)),
      filter(Boolean)
    );
  }

  static handleArrows(player, keyCode) {
    const ACTIONS = {
      [UP_ARROW_KEYCODE]: [
        TVShortcutController.selectErrorBackButton,
        TVShortcutController.selectResumePauseroll,
        TVShortcutController.selectSlider
      ],
      [DOWN_ARROW_KEYCODE]: [
        TVShortcutController.selectErrorBackButton,
        TVShortcutController.selectResumePauseroll,
        TVShortcutController.selectMenuFromTimeline
      ],
      [LEFT_ARROW_KEYCODE]: [
        TVShortcutController.selectErrorBackButton,
        TVShortcutController.selectResumePauseroll,
        TVShortcutController.selectNextMenuButton,
        TVShortcutController.rewindOnSlider
      ],
      [RIGHT_ARROW_KEYCODE]: [
        TVShortcutController.selectErrorBackButton,
        TVShortcutController.selectResumePauseroll,
        TVShortcutController.selectPreviousMenuButton,
        TVShortcutController.forwardOnSlider
      ]
    };

    const action = ACTIONS[keyCode] ? ACTIONS[keyCode].find((handler) => handler(player)) : null;

    return action ? action(player) : null;
  }

  static createUiVisible({ events$ }) {
    return events$.pipe(
      filter(({ name }) => name === UI_VISIBLE),
      map(({ payload }) => payload.visible),
      startWith(false) // ui not visible on mobile on first play
    );
  }

  /**
   *
   * stream current speed
   */
  static createCurrentSpeedStream({ handleArrows$, currentSpeed$ }) {
    return handleArrows$.pipe(
      filter(({ keyIdentifier, key, keyCode }) => TVShortcutController.getMappedKeyCode(TV_REMOTE_SPEED_CODES, keyIdentifier || key || keyCode)),
      map(({ keyIdentifier, key, keyCode }) => TVShortcutController.getMappedKeyCode(TV_REMOTE_SPEED_CODES, keyIdentifier || key || keyCode)),
      withLatestFrom(currentSpeed$),
      switchMap(([speedShortcut, currentSpeed]) => {
        const currentSpeedIndex = TV_REMOTE_SPEEDS.findIndex((matchCurrentSpeed) => matchCurrentSpeed === currentSpeed);
        const nextSpeedIndex = speedShortcut === TV_FORWARD
          ? currentSpeedIndex + 1
          : currentSpeedIndex - 1;

        return of(
          nextSpeedIndex < TV_REMOTE_SPEEDS.length && nextSpeedIndex >= 0
            ? TV_REMOTE_SPEEDS[nextSpeedIndex]
            : TV_REMOTE_SPEEDS[currentSpeedIndex]
        );
      })
    );
  }

  dispose() {
    window.removeEventListener('keydown', this.refHandleKeyEvent);
    window.removeEventListener('keydown', this.refHandleErrorsKeys);
    super.dispose();
  }

  /**
   *
   * force focus on resume video button on pauseroll UI
   */
  static selectResumePauseroll() {
    if (
      document.activeElement.querySelectorAll(SELECTOR_PAUSEROLL).length > 0
      || document.activeElement.classList.contains(BUTTON_NAME_RESUME_PAUSEROLL)
      || document.activeElement === document.getElementsByName(BUTTON_NAME_RESUME_PAUSEROLL)[0]
    ) {
      return document.getElementsByName(BUTTON_NAME_RESUME_PAUSEROLL)[0];
    }
    return null;
  }

  /**
   *
   * Select timeline
   */
  static selectSlider() {
    if (document.activeElement?.classList.contains(BUTTON_CLASS)) {
      return document.getElementsByName(SLIDER_NAME)[0];
    }
    return null;
  }

  /**
   *
   * Select menu item when focus is on timeline
   */
  static selectMenuFromTimeline() {
    if (document.activeElement.classList.contains(SLIDER_CLASS)) {
      const playButton = document.getElementsByName(BUTTON_NAME_PLAY)[0];
      const pauseButton = document.getElementsByName(BUTTON_NAME_PAUSE)[0];
      const stopButton = document.getElementsByName(BUTTON_NAME_STOP)[0];

      return playButton || pauseButton || stopButton;
    }
    return null;
  }

  /**
   *
   * Move focus on to the next selectable menu item LTR
   */
  static selectNextMenuButton() {
    if (document.activeElement.classList.contains(BUTTON_CLASS)) {
      const htmlButtons = document.getElementsByClassName(BUTTON_CLASS);
      const currentIndex = Array.from(htmlButtons).indexOf(document.activeElement);
      if (currentIndex > 0) {
        return htmlButtons[currentIndex - 1];
      }
      return htmlButtons[htmlButtons.length - 1];
    }
    return null;
  }

  /**
   *
   * Move focus on to the next selectable menu item RTL
   */
  static selectPreviousMenuButton() {
    if (document.activeElement.classList.contains(BUTTON_CLASS)) {
      const htmlButtons = document.getElementsByClassName(BUTTON_CLASS);
      const currentIndex = Array.from(htmlButtons).indexOf(document.activeElement);
      if (currentIndex < htmlButtons.length - 1) {
        return htmlButtons[currentIndex + 1];
      }
      return htmlButtons[0];
    }
    return null;
  }

  /**
   *
   * rewind when focus on timeline
   */
  static rewindOnSlider(player) {
    if (document.activeElement.classList.contains(SLIDER_CLASS)) {
      player.rewind();
    }
    return null;
  }

  /**
   *
   * forward when focus on timeline
   */
  static forwardOnSlider(player) {
    if (document.activeElement.classList.contains(SLIDER_CLASS)) {
      player.forward();
    }
    return null;
  }

  /**
   *
   * force focus on back button on error UI
   */
  static selectErrorBackButton() {
    if (
      document.getElementsByClassName(ERROR_CONTAINER_CLASS).length
      && !document.activeElement.classList.contains(BUTTON_CLASS)
    ) {
      return document.getElementById(BTN_BACK_HTML_ID);
    }
    return null;
  }
}
