import {
  ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE,
  ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE,
  replaceCodes
} from './orange';

/* Error messages */
const MESSAGE_TRY_AGAIN = 'Veuillez réessayer dans quelques minutes.';
const MESSAGE_SERVICE_UNAVAILABLE = 'Les services de France Télévisions sont temporairement indisponibles.';
const MESSAGE_TEAM_MOBILIZED = 'Nos équipes sont mobilisées pour résoudre ce problème dans les plus brefs délais.';
const MESSAGE_VIDEO_NOT_COMPATIBLE = 'L\'accès à cette vidéo est incompatible avec votre configuration.';
const MESSAGE_COMPATIBLE_BROWSERS = 'Pour profiter de nos vidéos, veuillez utiliser l\'un des navigateurs suivants : Chrome, Firefox, Microsoft Edge, Internet Explorer ou Safari, et le mettre à jour.';
export const MESSAGE_GEO_BLOCKED = 'Votre opérateur vous localise dans une zone pour laquelle cette vidéo n’est pas disponible.';
export const MESSAGE_DEFAULT = 'Une erreur est survenue lors de la lecture de la vidéo.';
export const MESSAGE_EPG_DEFAULT = 'Une erreur est survenue lors de la requête à l\'epg.';
export const MESSAGE_HIGHLIGHTS_DEFAULT = 'Une erreur est survenue lors de la requête à /highlights.';
export const MESSAGE_VIDEO_UNAVAILABLE = 'L\'accès à cette vidéo est impossible.';
export const MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE = 'L\'accès à cette vidéo est momentanément indisponible.';
export const MESSAGE_VIDEO_VPN_NOT_AUTHORIZED = 'L\'utilisation d\'un VPN ou d\'un proxy a été détectée. Veuillez le désactiver pour accéder à cette vidéo.';
export const MESSAGE_ISSUE_RETRY = "Une erreur s'est produite. Veuillez relancer la vidéo.";

/* Akamai error types */
export const AKAMAI_ERROR_HEADER = 'x-errortype';
export const AKAMAI_GEO_TYPE = 'geo';
export const AKAMAI_STOKEN_TYPE = 'stoken';
export const AKAMAI_LTOKEN_TYPE = 'ltoken';
export const AKAMAI_MON_ICUID_DEL_HEADER = 'akamai-mon-iucid-del';
/* Error types */
export const DEFAULT_TYPE = 'MagnetoError';
export const GATEWAY_TYPE = 'MagnetoGatewayError';
export const ACCESS_TYPE = 'MagnetoAccessError';
/* Amazon error types */
export const AMAZON_ERROR_HEADER = 'x-amzn-errortype';
/* Webservice default Errors */
export const WEBSERVICE_TIMEOUT_ERROR = {
  type: 'MagnetoWebserviceTimeoutError',
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: '',
  orangeTitle: ''
};

export const WEBSERVICE_BAD_RESPONSE_ERROR = {
  type: 'MagnetoWebserviceBadResponseError',
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: '',
  orangeTitle: ''
};

export const WEBSERVICE_SERVER_ERROR = {
  type: 'MagnetoWebserviceServerError',
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: '',
  orangeTitle: ''
};

/* 1000 Gateway Call Errors */
export const DEFAULT_GATEWAY_CALL_ERROR = {
  code: 1000,
  type: GATEWAY_TYPE,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1000),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_BAD_RESPONSE = {
  code: 1001,
  type: GATEWAY_TYPE,
  message: `${MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE}<br />${MESSAGE_TRY_AGAIN}`,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1001),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_TIMEOUT = {
  code: 1002,
  type: GATEWAY_TYPE,
  message: `${MESSAGE_SERVICE_UNAVAILABLE}<br />${MESSAGE_TEAM_MOBILIZED}<br />${MESSAGE_TRY_AGAIN}`,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1002),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_UNAUTHORIZED = {
  code: 1003,
  type: GATEWAY_TYPE,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1003),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_BAD_REQUEST = {
  code: 1004,
  type: GATEWAY_TYPE,
  message: `${MESSAGE_VIDEO_NOT_COMPATIBLE}<br />${MESSAGE_COMPATIBLE_BROWSERS}`,
  canRetry: false,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1004),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_UNAVALAIBLE = {
  code: 1005,
  type: GATEWAY_TYPE,
  message: `${MESSAGE_SERVICE_UNAVAILABLE}<br />${MESSAGE_TRY_AGAIN}`,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1005),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};

export const GATEWAY_BAD_UNAVALAIBLE = {
  code: 1008,
  type: GATEWAY_TYPE,
  message: `${MESSAGE_SERVICE_UNAVAILABLE}<br />${MESSAGE_TRY_AGAIN}`,
  canRetry: true,
  showCode: true,
  fatal: true,
  orangeErrorCode: 99,
  orangeUserCode: 'H09',
  orangeMessage: replaceCodes('H09', 1008),
  orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
};
/* 2000 */
export const ERRORS_MAP = [
  {
    code: 2000,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Un problème technique ne permet pas d’accéder au service que vous désirez. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2000',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2001,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Cette vidéo est déconseillée aux moins de 16 ans. Elle sera disponible entre 22h30 et 5h00.<br /> Code erreur : H09-2001',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2002,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Cette vidéo est déconseillée aux moins de 18 ans. Elle sera disponible entre 22h30 et 5h00.<br />Code erreur : H09-2002',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2003,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Cette vidéo n\'est malheureusement plus disponible.<br/> Code erreur : H09-2003',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2004,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Cette vidéo sera bientôt disponible. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche. <br />Code erreur : H09-2004',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2005,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Ce direct commencera le JJ/MM/AAAA à HHhMM.  Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2005',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2006,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Ce direct sera bientôt disponible.  Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2006',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2007,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Ce direct est terminé.<br />Code erreur : H09-2007',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2008,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: '#MESSAGE. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2008',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2009,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: '#MESSAGE.<br />Code erreur : H09-2009',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2010,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'L\'accès à cette vidéo est incompatible avec votre configuration. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2010',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2012,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: 'Cette vidéo n\'est malheureusement pas disponible.<br />Code erreur : H09-2012',
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  },
  {
    code: 2013,
    orangeErrorCode: 51,
    orangeUserCode: 'H03',
    orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-2013',
    orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
  },
  {
    code: 2015,
    orangeErrorCode: 53,
    orangeUserCode: 'H03',
    orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-2015',
    orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
  },
  {
    code: 2023,
    orangeErrorCode: 99,
    orangeUserCode: 'H09',
    orangeMessage: `L'accès à cette vidéo est momentanément indisponible. Merci de réessayer ultérieurement.
    <br />Si le problème persiste, rendez-vous sur orange.fr , rubrique assistance, tapez H09 dans le moteur de recherche.<br />Code erreur : H09-2008`,
    orangeTitle: ORANGE_MESSAGE_TITLE_SERVICE_UNAVAILABLE
  }

];
/* 3000 Access Errors */
export const ACCESS_DEFAULT_ERROR = {
  code: 3000,
  type: ACCESS_TYPE,
  canRetry: true,
  fatal: true,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3000',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const ACCESS_BAD_RESPONSE_ERROR = {
  code: 3001,
  type: ACCESS_TYPE,
  message: MESSAGE_VIDEO_UNAVAILABLE,
  canRetry: false,
  fatal: true,
  orangeErrorCode: 51,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3001',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
/* 3X02 , 3003 */
export const ACCESS_TOKEN_403_ERROR = {
  code: 3002,
  type: ACCESS_TYPE,
  message: MESSAGE_ISSUE_RETRY,
  canRetry: true,
  showCode: true,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3X02',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

/* 3X04 */
export const ACCESS_GEO_403_ERROR = {
  code: 3004,
  type: ACCESS_TYPE,
  message: MESSAGE_GEO_BLOCKED,
  canRetry: false,
  showCode: false,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Votre opérateur vous localise dans une zone pour laquelle cette vidéo n’est pas encore disponible.',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const ACCESS_4XX_ERROR = {
  code: 3005,
  type: ACCESS_TYPE,
  message: MESSAGE_VIDEO_UNAVAILABLE,
  canRetry: false,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3005',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const ACCESS_5XX_ERROR = {
  code: 3006,
  type: ACCESS_TYPE,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const ACCESS_TIMEOUT_ERROR = {
  code: 3007,
  type: ACCESS_TYPE,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3007',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const DRM_TOKEN_NOT_RECEIVED_ERROR = {
  code: 3008,
  type: 'MagnetoDrmError',
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  canRetry: true,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3008',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
/* 3X10 */
export const ACCESS_WITH_VPN_NOT_AUTH = {
  code: 3010,
  type: ACCESS_TYPE,
  message: MESSAGE_VIDEO_VPN_NOT_AUTHORIZED,
  canRetry: false,
  showCode: false,
  fatal: true,
  orangeErrorCode: 50,
  orangeUserCode: 'H03',
  orangeMessage: 'L\'utilisation d\'un VPN ou d\'un proxy a été détectée. Merci de le désactiver pour accéder à cette vidéo.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-3X10',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_TOKEN_403_ERROR = {
  code: 4010,
  type: 'MagnetoRendererNetworkError',
  message: MESSAGE_VIDEO_UNAVAILABLE,
  canRetry: false,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X10',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
/* 4X11 */
export const RENDERER_GEO_403_ERROR = {
  code: 4011,
  type: 'MagnetoRendererNetworkError',
  message: MESSAGE_GEO_BLOCKED,
  canRetry: false,
  showCode: false,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Votre opérateur vous localise dans une zone pour laquelle cette vidéo n’est pas encore disponible. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X11',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
/* 4X12 */
export const RENDERER_WITH_VPN_NOT_AUTH = {
  code: 4012,
  type: 'MagnetoRendererNetworkError',
  message: MESSAGE_VIDEO_VPN_NOT_AUTHORIZED,
  canRetry: false,
  showCode: false,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'L\'utilisation d\'un VPN ou d\'un proxy a été détectée. Veuillez le désactiver pour accéder à cette vidéo.<br />Code erreur : H03-4X12',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

/* 4000 Renderer Errors */
export const RENDERER_DEFAULT_ERROR = {
  type: 'MagnetoGenericRendererError',
  code: 4000,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X00',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
// 4X01
export const RENDERER_NETWORK_ERROR = {
  type: 'MagnetoRendererNetworkError',
  code: 4001,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X01',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
// 4X02
export const RENDERER_500_ERROR = {
  type: 'MagnetoRendererNetworkError',
  code: 4002,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X02',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
// 4X03
export const RENDERER_403_ERROR = {
  type: 'MagnetoRendererNetworkError',
  code: 4003,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 53,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X03',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_404_ERROR = {
  type: 'MagnetoRendererNetworkError',
  code: 4004,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 52,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X04',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_TIMEOUT_ERROR = {
  type: 'MagnetoRendererNetworkError',
  code: 4005,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X05',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_MEDIA_ERROR = {
  type: 'MagnetoMediaError',
  code: 4006,
  message: MESSAGE_DEFAULT,
  fatal: true,
  orangeErrorCode: 52,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X06',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};
// 4X07
export const RENDERER_LIVE_STREAM_STALLED = {
  type: 'MagnetoLiveStreamStalledError',
  code: 4007,
  message: MESSAGE_DEFAULT,
  canRecover: true,
  orangeErrorCode: 52,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X07',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_PROTECTION_ERROR = {
  type: 'MagnetoProtectionError',
  code: 4008,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  fatal: true,
  canRetry: true,
  orangeErrorCode: 53,
  orangeUserCode: 'H03',
  orangeMessage: 'Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Merci de réessayer ultérieurement.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X08',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_AWS_ERROR = {
  type: 'MagnetoAwsError',
  code: 4009,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  fatal: true,
  canRetry: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-4X09',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const RENDERER_VIDEO_READ_ERROR = {
  type: 'MagnetoAwsError',
  code: 5000,
  message: MESSAGE_VIDEO_TEMPORARILY_UNAVAILABLE,
  fatal: true,
  canRetry: true,
  orangeErrorCode: 40,
  orangeUserCode: 'H03',
  orangeMessage: 'Une erreur est survenue lors de la lecture de la vidéo. Merci de réessayer.<br />Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.<br />Code erreur : H03-5000',
  orangeTitle: ORANGE_MESSAGE_TITLE_PROGRAM_UNAVAILABLE
};

export const findErrorByCode = (ecode, message = '') => {
  const MESSAGE = '#MESSAGE';
  const matchError = ERRORS_MAP.find(({ code }) => code === ecode);
  return matchError
    ? { ...matchError, ...(matchError.orangeMessage.includes(MESSAGE) ? { orangeMessage: matchError.orangeMessage.replace(MESSAGE, message) } : {}) }
    : {};
};
