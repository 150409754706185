/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlaylistIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <mask
        id="mask0_8478_18273"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="22"
        width="55"
        height="46"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5276 27.2581C31.5276 24.6303 33.646 22.5 36.2592 22.5H65.5809C68.1941 22.5 70.3125 24.6303 70.3125 27.2581V47.6844C70.3125 50.3122 68.1941 52.4425 65.5809 52.4425H63.2831V55.4241C63.2831 58.0519 61.1647 60.1822 58.5515 60.1822H55.6599V63.1638C55.6599 65.7916 53.5415 67.9219 50.9283 67.9219H21.6066C18.9934 67.9219 16.875 65.7916 16.875 63.1638V42.7375C16.875 40.1097 18.9934 37.9794 21.6066 37.9794H24.4982V34.9978C24.4982 32.37 26.6166 30.2397 29.2298 30.2397H31.5276V27.2581ZM27.2456 37.9794H50.9283C53.5415 37.9794 55.6599 40.1097 55.6599 42.7375V57.4194H58.5515C59.6474 57.4194 60.5357 56.5261 60.5357 55.4241V34.9978C60.5357 33.8958 59.6474 33.0025 58.5515 33.0025H29.2298C28.1339 33.0025 27.2456 33.8958 27.2456 34.9978V37.9794ZM65.5809 49.6797H63.2831V34.9978C63.2831 32.37 61.1647 30.2397 58.5515 30.2397H34.275V27.2581C34.275 26.1561 35.1633 25.2628 36.2592 25.2628H65.5809C66.6768 25.2628 67.5651 26.1561 67.5651 27.2581V47.6844C67.5651 48.7864 66.6768 49.6797 65.5809 49.6797ZM19.6224 42.7375C19.6224 41.6355 20.5107 40.7422 21.6066 40.7422H50.9283C52.0242 40.7422 52.9125 41.6355 52.9125 42.7375V63.1638C52.9125 64.2658 52.0242 65.1591 50.9283 65.1591H21.6066C20.5107 65.1591 19.6224 64.2658 19.6224 63.1638V42.7375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8478_18273)" transform="scale(0.66)">
        <rect width={width * 1.5} height={height * 1.5} fill="black" />
        <rect width={width * 1.5} height={height * 1.5} fill="#F8F8F8" />
      </g>

    </SVGWrapper>
  );
}

export default TvPlaylistIcon;
