import { Disposable } from '..';

export const LOAD_MAP = {
  piano: { load: () => import('../../monitoring/piano') },
  orange: { load: () => import('../../monitoring/orange') },
  eStat: { load: () => import('../../monitoring/eStat') }
};

export default class MonitorManagerController extends Disposable {
  async init({ load, name, args }) {
    const Module = (await load()).default;
    this[name] = new Module(...args);
    this.name = name;
  }

  dispose() {
    this[this.name]?.dispose();
    super.dispose();
  }
}
