export const TIMESHIFTING_TYPE_ADSWITCHING = 'adswitching';

export const TIMESHIFTING_SEEK = 'timeshiftingSeek';
export const TIMESHIFTING_SHIFTING_TO_START_REQUESTED = 'timeshiftingShiftingToStartRequested';
export const TIMESHIFTING_BACK_TO_LIVE_REQUESTED = 'timeshiftingBackToLiveRequested';

export const TIMESHIFTING_ESTAT_LIVE = 'timeshiftingEstatLive';
export const TIMESHIFTING_ESTAT_SHIFTING = 'timeshiftingEstatShifting';

export const EPG_CURRENT_CHANGED = 'epg_current_changed';
export const EPG_CURRENT_START = 'epg_current_start';

export const TIMESHIFTING_TIME_MARGIN = 30;
export const TIMESHIFTING_TIME_SHOULD_SHOW_START_OVER = 60;
export const TIMESHIFTING_CONTROLLER_NAME = 'timeshiftingController';
export const TIMESHIFTING_TIME_MARGIN_BACK_TO_LIVE = 15;
export const TIMESHIFTING_TYPE_MANUEL = 'manuel';

export const TIMESHIFTING_ESTAT_LIVE_TIME_MARGIN = 50;
export const TIMESHIFTING_ESTAT_SHIFTING_TIME_MARGIN = 60;

export const TIMESHIFTING_TYPE_AUTO = 'auto';

/* 4h dvr window on simulcasts with a minute cushin to avoid going over the 4 hours time window */
export const TIMESHIFTING_AUTO_DURATION_MARGIN = 60;
export const TIMESHIFTING_AUTO_DURATION = (4 * 60 * 60) - TIMESHIFTING_AUTO_DURATION_MARGIN;

export const TIMESHIFTING_AUTO_TRIGGERED = 'timeshiftingAutoTriggered';
export const TIMESHIFTING_AUTO_CONTROLLER_NAME = 'timeshiftingAutoController';
export const TIMESHIFTING_AUTO_TIME_MARGIN_BEGIN_CURRENT_PROGRAM = 60 * 2; // 2min
export const TIMESHIFTING_AUTO_IN_BOX_START_PROGRAM = 'timeshiftingAutoInBoxStartProgram';

export const TIMESHIFTING_DIFFERED = 'timeshifting_differed';
export const TIMESHIFTING_DIFFERED_PAST = 'timeshifting_differed_past';
export const TIMESHIFTING_LIVE = 'timeshifting_live';
export const TIMESHIFTING_LIVE_BACK = 'timeshifting_live_back';

export const TIMESHIFTING_FLICKERING_TIME_MARGIN = 0.5;
export const TIMESHIFTING_FAKE_LIVE_BROADCASTED_AT = 100;
export const TIMESHIFTING_START_OVER_TIME_MARGIN = 10;
export const FLICKERING_MARGIN_SEGMENT = 1.5; // as second
