import { h } from 'preact';
import { SVGCreator } from '..';
import { DEFAULT_BUTTON_STYLE } from './styles';
import { BUTTON_SVG_RATIO } from '../../../theme/constants';
import { systemInfo, VOID_FN } from '../../../../utils';

const clickHandler = (e, { onClick, onInteract, mobileFriendly }) => {
  e.preventDefault();
  e.stopPropagation();
  if (mobileFriendly) {
    /* Hide UI on click if mobileFriendly */
    onInteract(false);
  }

  onClick(e);
};

function ControlButton({
  svg,
  size,
  xl = false,
  style,
  styleOverride,
  onClick,
  onBlur,
  onInteract = VOID_FN,
  onHover = VOID_FN,
  onKeyDown = VOID_FN,
  mobileFriendly,
  frame,
  mobile,
  ariaLabel,
  disableFocus = false,
  disableCloseFocus = true,
  name,
  ariaHidden = 'true',
  activeIconRef,
  iconRef,
  handleFocusWithin,
  handleBlurWithin
}) {
  return (
    <div
      style={{
        width: size,
        height: size,
        ...styleOverride
      }}
      role="button"
      onClick={(e) => clickHandler(e, { onClick, onInteract, mobileFriendly })}
      {...(mobile ? {} : {
        onMouseEnter: () => {
          onHover(true);
          onInteract(true);
        },
        onMouseLeave: () => {
          onHover(false);
          onInteract(false);
        }
      })
    }
      onKeyDown={(e) => {
        if (e.keyCode === 13 || e.keyCode === 32 /* Enter or Spacebar */) {
          clickHandler(e, { onClick, onInteract, mobileFriendly });
          onKeyDown(e);
        }
      }}
      tabIndex={disableFocus ? '-1' : '0'}
      className="ftv-magneto--btn ftv-magneto--focusable-item"
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      name={name}
      aria-live={systemInfo.isMac ? 'polite' : 'off'}
      aria-atomic="true"
      ref={iconRef}
      onFocus={handleFocusWithin}
      onBlur={(e) => {
        if (onBlur) onBlur(e);
        handleBlurWithin(e);
      }}
    >
      <div
        role="button"
        className={disableCloseFocus || 'ftv-magneto--btn ftv-magneto--focusable-item'}
        style={{
          ...DEFAULT_BUTTON_STYLE,
          ...style,
          width: size,
          height: size,
          borderRadius: disableCloseFocus ? (size / 2) : 2
        }}
        name={name}
        tabIndex={disableCloseFocus ? -1 : 0}
        ref={activeIconRef}
      >
        <SVGCreator
          paths={svg}
          frame={frame}
          width={size * (xl ? 1 : BUTTON_SVG_RATIO)}
          height={size * (xl ? 1 : BUTTON_SVG_RATIO)}
        />
      </div>
    </div>
  );
}

export default ControlButton;
