export const DISABLED_AD = 'publicite_desactivee';
export const BLOCKED_AD = 'publicite_bloquee';
export const CAPPING_CLIENT = 'capping_client';
export const NO_ADS_SENT = 'pas_de_publicite_renvoyee';

export const REPLAY_PLAYER_FROM_ZONE = 'rejouer_player';

/* Event Name */
export const PIANO_EVENT_PLAYER_INTERACTION = 'player.interaction';
export const PIANO_EVENT_PLAYER_DAI = 'player.dai';
export const PIANO_EVENT_PLAYER_KEEP_ALIVE = 'player.keepalive';
export const PIANO_EVENT_PLAYER_START = 'player.start';
export const PIANO_EVENT_PLAYER_MIDROLL = 'player.midroll';
export const PIANO_EVENT_PLAYER_PAUSEROLL = 'player.pauseroll';

export const PIANO_EVENTS_PLAYER_LIST = [
  PIANO_EVENT_PLAYER_INTERACTION,
  PIANO_EVENT_PLAYER_DAI,
  PIANO_EVENT_PLAYER_KEEP_ALIVE,
  PIANO_EVENT_PLAYER_START,
  PIANO_EVENT_PLAYER_MIDROLL
];
/* Data Values */
export const PIANO_ACTIVATION = 'activation';
export const PIANO_DEACTIVATION = 'desactivation';
export const PIANO_ACTIVE = 'active';
export const PIANO_DEACTIVE = 'desactive';
export const PIANO_FIRST = 'premier';
export const PIANO_FORBIDDEN = 'interdit';
export const PIANO_AUTORIZED = 'autorise';

export const PIANO_VALUE_PIP = 'pip';

export const PIANO_VALUE_SHOW_SKIP_INTRO = 'affichage_skip_intro';
export const PIANO_VALUE_SHOW_SKIP_RECAP = 'affichage_skip_previously';

export const PIANO_VALUE_CLICK_SKIP_INTRO = 'clic_skip_intro';
export const PIANO_VALUE_CLICK_SKIP_RECAP = 'clic_skip_previously';

export const PIANO_VALUE_SHOW_COMING_NEXT = 'affichage_skip_coming_next';
export const PIANO_VALUE_CLICK_COMING_NEXT = 'clic_skip_coming_next';
export const PIANO_VALUE_SHOW_SETTINGS = 'affichage_parametres';
export const PIANO_VALUE_SHOW_TRACKS = 'affichage_sous_titres_audio';

export const PIANO_VALUE_CHOICE_AUDIO = 'choix_audio';
export const PIANO_VALUE_CHOICE_SUBTITLE = 'choix_sous_titres';

export const PIANO_VALUE_NEXT_PREVIOUS_TUNNEL = 'next_previous_tunnel';
export const PIANO_VALUE_RATE_QUALITY = 'noter_la_qualite_video';
export const PIANO_VALUE_REPORT_VIDEO = 'signaler_la_video';
export const PIANO_VALUE_PLAYBACK_RATE_MENU = 'affichage_vitesse_de_lecture';
export const PIANO_VALUE_PLAYBACK_RATE_CHOICE = 'choix_vitesse_de_lecture';

export const PIANO_VALUE_VOLUME = 'volume';

export const PIANO_VALUE_DAI_BEFORE_AD = 'dai_avant_pub';
export const PIANO_VALUE_DAI_AFTER_AD = 'dai_apres_pub';

export const PIANO_VALUE_QUALITY_MENU = 'affichage_qualite';
export const PIANO_VALUE_QUALITY_CHOICE = 'choix_qualite';

export const PIANO_VALUE_SEEK = 'seek';
export const PIANO_VALUE_SEEK_PLUS_10 = 'plus_10';
export const PIANO_VALUE_SEEK_MINUS_10 = 'moins_10';

export const PIANO_VALUE_RETRY_BUTTON = 'reessayer';

export const PIANO_VALUE_ZAPPING_DISPLAY = 'affichage_zapette';
export const PIANO_VALUE_ZAPPING_META_DISPLAY = 'affichage_metadonnees_zapette';
export const PIANO_VALUE_ZAPPING_CLICKED = 'clic_zapette';
export const PIANO_VALUE_ZAPPING_META_CLICKED = 'clic_metadonnees_zapette';
export const PIANO_VALUE_CHAT_DISPLAY = 'affichage_chat';

export const PIANO_VALUE_LIVE_PANEL_DEFAULT_POSITION = 'panel';
export const PIANO_VALUE_TIMELINE_POSITION = 'timeline';

export const PIANO_VALUE_ENCHAINEMENT_AUTO_TUNNEL = 'enchainement_auto_tunnel';
export const PIANO_VALUE_START_TUNNEL = 'debut_tunnel';

export const PIANO_VALUE_RETRY = 'reessayer';
export const PIANO_VALUE_RELOAD = 'relancer';
export const PIANO_VALUE_REPLAY = 'rejouer';
export const PIANO_VALUE_CLOSE = 'fermer';

export const PIANO_VALUE_START_PREFIX = 'lancement';
export const PIANO_VALUE_AD_LESS = 'sans_pub';
export const PIANO_VALUE_AD_BEFORE = 'avant_pub';
export const PIANO_VALUE_AD_AFTER = 'apres_pub';

export const PIANO_VALUE_FULLSCREEN = 'fullscreen';

export const PIANO_VALUE_MIDROLL_PREFIX = 'midroll';

export const PIANO_VALUE_TIMESHIFTING_START = 'timeshifting_debut_du_programme';
export const PIANO_VALUE_TIMESHIFTING_BACK_TO_LIVE = 'timeshifting_retour_au_direct';
export const PIANO_VALUE_TIMESHIFTING_DIFFERED = 'timeshifting_passage_differe';
export const PIANO_VALUE_LIVE_PROGRAM = 'programme_antenne';
export const PIANO_VALUE_DIFFERED_PROGRAM = 'programme_passe';
export const PIANO_VALUE_TIMESHIFTING_SEEK = 'timeshifting_seek';
export const PIANO_VALUE_BACK_TO_START = 'retour_au_début';
export const PIANO_VALUE_BACK_TO_LIVE = 'retour_au_direct';

export const PIANO_VALUE_SHOW_ECO = 'affichage_player_green';
export const PIANO_VALUE_ECO_CHOICE = 'choix_player_green';
export const PIANO_VALUE_INTERACTION_KEYBOARD = 'touche_clavier';
export const PIANO_VALUE_INTERACTION_CLICK = 'clic';

export const PIANO_VALUE_POSITION_ECO = 'mode-eco';
export const PIANO_VALUE_POSITION_VIDEO_QUALITY = 'qualite-video';
export const PIANO_DISPLAY_RECO_UI = 'affichage_interface_reco';
export const PIANO_DISPLAYED_RECO_UI = 'reco_affichee';
export const PIANO_NO_DISPLAYED_RECO_UI = 'reco_non_affichee';
export const PIANO_CLICK_RECO_UI = 'clic_interface_reco';
export const PIANO_RECO_WATCH_CLIC = 'regarder';
export const PIANO_RECO_REPLAY_CLIC = 'rejouer';
export const PIANO_RECO_BACK_TO_CREDITS_CLIC = 'retour_generique';
export const PIANO_NO_RECO_RESTART_SCREEN_CAUSE = 'rejouer_only';

export const PIANO_VALUE_HIGHLIGHTS_DISPLAY = 'affichage_tempsforts';
export const PIANO_VALUE_HIGHLIGHTS_CLICKED = 'clic_tempsforts';

export const PIANO_VALUE_PAUSEROLL_AD_LESS = 'pauseroll_sans_pub';
export const PIANO_VALUE_PAUSEROLL_AD_BEFORE = 'pauseroll_avant_pub';
export const PIANO_VALUE_PAUSEROLL_AD_AFTER = 'pauseroll_apres_pub';

export const PIANO_PLAYER_PROPERTIES_LIST = [
  'site_id',
  'page',
  'page_type',
  'player_version',
  'visitor_login_status',
  'visitor_id',
  'feature',
  'feature_status',
  'content_status',
  'program',
  'content_title',
  'cause',
  'content_type',
  'content_diffusion_date',
  'content_logged',
  'content_id',
  'content_rank',
  'video_factory_id',
  'season',
  'place_tunnel',
  'zone',
  'origin_page',
  'origin_page_type',
  'position',
  'break_duration',
  'break_duration_expected',
  'break_ads_number',
  'break_ads_number_expected',
  'langue_demarrage',
  'sous_titres_demarrage',
  'vitesse_demarrage',
  'qualite_demarrage',
  'interaction',
  'progression',
  'channel',
  'highlight',
  'fullscreen',
  'region',
  'broadcastingType',
  'origin_server',
  'dai_status',
  'option_highlights',
  'option_pip',
  'option_showad',
  'option_livechat',
  'option_zap'

];
export const VIDEO_RANK = 'video_rank';
