import { combineLatest, Subject, merge, interval, fromEvent, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, filter, mapTo, skip, scan, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  TIMESHIFTING_BACK_TO_LIVE_REQUESTED,
  TIMESHIFTING_ESTAT_LIVE,
  TIMESHIFTING_ESTAT_SHIFTING,
  TIMESHIFTING_SHIFTING_TO_START_REQUESTED,
  TIMESHIFTING_TIME_MARGIN_BACK_TO_LIVE,
  TIMESHIFTING_TIME_SHOULD_SHOW_START_OVER,
  TIMESHIFTING_ESTAT_LIVE_TIME_MARGIN,
  TIMESHIFTING_ESTAT_SHIFTING_TIME_MARGIN
} from './types';
import { TIMESHIFTING_NPAW_LIVE_TIME_MARGIN } from '../../monitoring/npaw/types';
import { systemInfo } from '../../utils';
import { Disposable } from '..';

export default class TimeShiftingController extends Disposable {
  constructor(player) {
    super();
    const {
      rendererController: { currentTime$, duration$ },
      mediaController: { medias$ }
    } = player;
    this.stream$ = new Subject();
    this.request$ = new Subject();
    this.metadatas$ = new Subject(); /* List of the metadata in range */

    this.currentMetadata$ = new ReplaySubject(1);
    this.currentProgramIndex$ = new Subject();
    this.isOnLiveCurrentProgram$ = new BehaviorSubject(true);

    this.currentTime$ = currentTime$;
    this.programPositions$ = new Subject();

    this.canDisplayStartOverStream$ = TimeShiftingController.createCanDisplayStartOverStream(this.currentTime$, medias$);
    this.setupSubscription(player);
    this.handleBackToLive(player);
    this.handleStartOverTimeshifting(player);

    this.isTimeshiftingStreamLive$ = TimeShiftingController
      .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_TIME_SHOULD_SHOW_START_OVER, TIMESHIFTING_NPAW_LIVE_TIME_MARGIN);
  }

  setupSubscription(player) {
    const {
      userEvents$,
      rendererController: { currentTime$, duration$ },
      mediaController: { medias$ }
    } = player;

    this.stream$ = merge(
      this.request$,
      userEvents$.pipe(map(({ action }) => action))
    );

    TimeShiftingController
      .createEstatTrackingStream(currentTime$, duration$, medias$)
      .subscribe(this.request$);

    TimeShiftingController
      .setupRetryOnSleep(medias$)
      .subscribe(TimeShiftingController.backFromSleep.bind(null, player));
  }

  backToLive() { this.request$.next(TIMESHIFTING_BACK_TO_LIVE_REQUESTED); }

  startOver() { this.request$.next(TIMESHIFTING_SHIFTING_TO_START_REQUESTED); }

  /**
   * Create a stream where we can check if we are on timeshifting delayed or not base on X second
   * false => on delayed timeshifting
   * true => on live
   * margin explanation => 60 sec for shifting and 50 for live in order to avoid glitch :
   * case live : {
   *  seek(51) => stay live
   *  seek(61) => timeshifting
   * }
   * case timeshifting : {
   *  seek(51) => stay timeshifting
   *  seek(49) => live
   * }
   * @param {Observable} currentTime$
   * @param {Observable} duration$
   * @param {Observable} medias$
   * @param {Number} margin
   */
  static createIsStreamLive(currentTime$, duration$, medias$, shiftingMargin, liveMargin) {
    return combineLatest(currentTime$, duration$, medias$)
      .pipe(
        filter(([current, duration, { video: { timeshiftable } }]) => Boolean(timeshiftable) && duration > 0 && current > 0),
        scan((isLive, [current, duration]) => (isLive ? shiftingMargin : liveMargin) >= Math.abs(duration - current), true),
        distinctUntilChanged()
      );
  }

  /**
   * Create a stream where we can check if we are X second based on the broadcasted date
   *
   * @param {Observable} currentTime$
   * @param {Observable} medias$
   * @param {Observable} margin
   */
  static createCanDisplayStartOverStream(currentTime$, medias$, margin = TIMESHIFTING_TIME_SHOULD_SHOW_START_OVER) {
    return combineLatest(currentTime$, medias$)
      .pipe(
        filter(([, { video: { timeshiftable } }]) => Boolean(timeshiftable)),
        map(([currentTime]) => currentTime >= margin),
        distinctUntilChanged()
      );
  }

  static onBackToLive(player, chattable) {
    player.seek(
      player.getDuration() - (chattable ? 0 : TIMESHIFTING_TIME_MARGIN_BACK_TO_LIVE),
      true
    );
  }

  handleBackToLive(player) {
    return this.stream$
      .pipe(
        filter((event) => event === TIMESHIFTING_BACK_TO_LIVE_REQUESTED),
        withLatestFrom(player.playerConfig$, (_, { chattable }) => chattable)
      )
      .subscribe(TimeShiftingController.onBackToLive.bind(null, player));
  }

  static onStartOverTimeshifting(player) {
    player.seek(0, true);
  }

  handleStartOverTimeshifting(player) {
    return this.stream$
      .pipe(filter((event) => event === TIMESHIFTING_SHIFTING_TO_START_REQUESTED))
      .subscribe(TimeShiftingController.onStartOverTimeshifting.bind(null, player));
  }

  /**
   * Stream that check if the flux is on live for Estat
   * @param {Observable} currentTime$ currentTime stream
   * @param {Observable} duration$ duration stream
   * @param {Observable} medias$ medias stream
   */
  static createIsLiveEstatStream(currentTime$, duration$, medias$) {
    return TimeShiftingController
      .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_ESTAT_LIVE_TIME_MARGIN, TIMESHIFTING_ESTAT_LIVE_TIME_MARGIN)
      .pipe(
        filter((isLive) => isLive),
        skip(1),
        mapTo(TIMESHIFTING_ESTAT_LIVE)
      );
  }

  /**
   * Stream that check if the flux is in timeshiftinig
   * @param {Observable} currentTime$ currentTime stream
   * @param {Observable} duration$ duration stream
   * @param {Observable} medias$ medias stream
   */
  static createIsShiftingEstatStream(currentTime$, duration$, medias$) {
    return TimeShiftingController
      .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_ESTAT_SHIFTING_TIME_MARGIN, TIMESHIFTING_ESTAT_SHIFTING_TIME_MARGIN)
      .pipe(
        filter((isLive) => !isLive),
        mapTo(TIMESHIFTING_ESTAT_SHIFTING)
      );
  }

  /**
   * @param {Observable} currentTime$ currentTime stream
   * @param {Observable} duration$ duration stream
   * @param {Observable} medias$ medias stream
   */
  static createEstatTrackingStream(currentTime$, duration$, medias$) {
    return merge(
      TimeShiftingController.createIsLiveEstatStream(currentTime$, duration$, medias$),
      TimeShiftingController.createIsShiftingEstatStream(currentTime$, duration$, medias$)
    );
  }

  static setupRetryOnSleep(media$) {
    return media$.pipe(
      filter(({ isLive }) => isLive),
      filter(() => ['edge', 'chrome'].includes(systemInfo.browser)),
      switchMap(() => interval(15000).pipe(
        scan(({ date }) => {
          const now = Date.now();
          if (now - date > 15000 * 1.5) return { date: now, hasSlept: true };
          return { date: now, hasSlept: false };
        }, { date: Date.now(), hasSlept: false }),
        filter(({ hasSlept }) => hasSlept),
        switchMap(() => fromEvent(window.document, 'focusin'))
      ))
    );
  }

  static backFromSleep(player) {
    player.retry();
  }
}
