export const DEFAULT_SETTINGS = {
  mute: false,
  speed: 1,
  quality: -1,
  tunnelActivated: true,
  volume: 1,
  languages: {
    audio: 'fr',
    subtitles: null
  }
};

export const LOCAL_PLAYER_PREFERENCES = 'player-preferences';
export const TYPE_TRACKING_PREFERENCE = 'preference';
export const TYPE_TRACKING_DEFAULT = 'defaut';

export const DEFAULT_TIMEOUT_TRACKS = 1000;

export const FRENCH_TRACKS = ['fr', 'fra', 'fre', 'qsa'];
