/* eslint-disable no-new */
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { RadioGroup } from '../../../../ui/utils/RadioGroup';
import Track from '../track';
import { FIRST_TRACKS_SECTION, GAP, TRACKS_DIVIDER, TRACKS_SECTION_MAIN_TITLE, TRACKS_SECTION_TITLES } from './styles';
import Seperator from '../../../../ui/components/common/menu/Seperator';

function TrackList({
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected
}) {
  useEffect(() => {
    new RadioGroup([], 'audio-subtitles', true);
  }, []);

  return (
    <div name="tracks-view" className="ftv-magneto--tracks">
      <h2 style={TRACKS_SECTION_MAIN_TITLE}>Versions disponibles</h2>

      <div style={GAP} />

      <div style={FIRST_TRACKS_SECTION}>
        <h3 style={TRACKS_SECTION_TITLES}>audio</h3>

        {audiosAvailable.map(({ index, language, label: { label, icon } }) => (
          <Track
            index={index}
            language={language}
            label={label}
            isActive={index === audioSelected}
            id="audio"
            icon={icon}
          />
        ))}
      </div>

      <Seperator style={TRACKS_DIVIDER} />
      <div style={GAP} />

      <div>
        <h3 style={TRACKS_SECTION_TITLES}>sous-titres</h3>
        <Track
          index={-1}
          language="n/a"
          label="aucun"
          isActive={subtitleSelected === -1}
          id="subtitle"
        />

        {subtitlesAvailable.map(
          ({ index, language, label: { label, icon } }) => (
            <Track
              index={index}
              language={language}
              label={label}
              isActive={index === subtitleSelected}
              id="subtitle"
              icon={icon}
            />
          )
        )}
      </div>
    </div>
  );
}

export default TrackList;
