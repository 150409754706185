import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { withBreakPoints, Delayed, connect } from '../../hoc';
import { Button } from '../common';
import SpinnerFranceTV from '../spinner/spinner';
import { START_SCREEN_PLAYBUTTON, START_SCREEN_CONTAINER } from '../../styles';
import {
  START_SCREEN_CONTAINER_HIDDEN,
  START_SCREEN,
  START_SCREEN_BUTTON_SIZE,
  START_SCREEN_BUTTON_SIZE_MINI
} from './styles';
import { usePlayerContext, useStyles } from '../../hooks';
import { publicPath } from '../../../configuration';
import { TAP_LAYER } from '../../theme/constants';
import { USER_CLICK, USER_REPLAY } from '../../../types';

function StartScreen({
  isMounted,
  playButtonReady,
  backgroundImage,
  showRestartButtonOnStartScreen,
  width,
  height
}) {
  const { size } = useStyles()({
    'large+extraLarge': { size: START_SCREEN_BUTTON_SIZE },
    default: { size: START_SCREEN_BUTTON_SIZE_MINI }
  });
  const player = usePlayerContext();

  const startButtonRef = useRef();

  useEffect(() => {
    if (showRestartButtonOnStartScreen && startButtonRef.current) { startButtonRef.current.focus(); }
  }, [showRestartButtonOnStartScreen, startButtonRef.current]);

  return (
    <Delayed
      isMounted={isMounted}
      shouldDelay
      delay={300}
    >
      {() => (
        <div
          style={{
            ...START_SCREEN_CONTAINER,
            ...(!isMounted ? START_SCREEN_CONTAINER_HIDDEN : {}),
            zIndex: TAP_LAYER
          }}
        >
          <div
            className="ftv--magneto-startscreen"
            style={{
              ...START_SCREEN,
              backgroundImage: `url(${backgroundImage.match(/background\.jpg/) ? `${publicPath}${backgroundImage}` : backgroundImage})`,
              transform: 'scale(1.009)',
              backgroundSize: width < height ? 'contain' : 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          />
          <div style={START_SCREEN_PLAYBUTTON}>
            {!playButtonReady && <SpinnerFranceTV isMounted />}
            <Button
              type="icon"
              icon={showRestartButtonOnStartScreen ? 'replay' : 'play'}
              hidden={!playButtonReady}
              size={size}
              style={{
                pointerEvents: 'none'
              }}
              xl
              name="btn-first-play"
              disableFocus={false}
              ariaHidden="false"
              ariaLabel={showRestartButtonOnStartScreen ? 'regarder à nouveau' : 'lecture'}
              label={showRestartButtonOnStartScreen && 'regarder à nouveau'}
              forceLabel
              onClick={() => {
                if (showRestartButtonOnStartScreen) {
                  player.userEvents$.next({
                    action: USER_REPLAY,
                    source: USER_CLICK
                  });
                }
                player.play({ userGesture: true });
              }}
              iconRef={startButtonRef}
              disableCloseFocus
            />
          </div>
        </div>
      )}
    </Delayed>
  );
}

const selector = ({
  media: { backgroundImage },
  ui: { playButtonReady, showRestartButtonOnStartScreen, width, height }
}) => ({ backgroundImage, playButtonReady, showRestartButtonOnStartScreen, width, height });
export default withBreakPoints(connect(selector)(StartScreen));
