import { Fragment, h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { PANEL_CLOSE_BUTTON, PANEL_CLOSE_BUTTON_SIZE, PANEL_CLOSE_LABEL, PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED, PANEL_TITLE } from './styles';
import { Delayed, withBreakPoints } from '../../../hoc';
import { Button } from '../../common';
import { USER_CLICK, USER_KEYBOARD, USER_PANEL_LIVE_OPTIONS_CLOSE } from '../../../../types';
import { isValidateKeyboard } from '../../../utils/keyboard';
import { TOGGLE_SHOW_PANNEL_LIVE_OPTION } from '../../../../store/types';

import ProgramTypeSwitch from '../../zap/ProgramTypeSwitch';

import Programs from '../../zap/Programs';
import { CHAT_NAME, HIGHLIGHTS_NAME, ZAPPING_NAME } from './constants';
import Chat from './Chat';
import { TRANSITION_DURATION } from '../../../theme/colors';
import '../../../theme/styles/panel.css';

const TITLES_MAPPING = {
  [ZAPPING_NAME]: 'Tous les directs',
  [HIGHLIGHTS_NAME]: 'Les temps forts',
  [CHAT_NAME]: 'Live chat'
};

function PanelLiveOption({ panelLiveOption, small, width, height, panelHeight }) {
  const setStyles = useStyles();
  const player = usePlayerContext();
  const onClose = useCallback((fromKeyboard) => {
    player.store.dispatch({
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      payload: {
        show: false,
        currentTab: ''
      }
    });
    player.userEvents$.next({
      action: USER_PANEL_LIVE_OPTIONS_CLOSE,
      value: false,
      source: fromKeyboard ? USER_KEYBOARD : USER_CLICK
    });
  });

  const isPortrait = width < height;

  const panelWrapperStyle = useMemo(() => {
    if (small) {
      return {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        borderRadius: 0
      };
    }

    if (isPortrait) {
      return {
        position: 'absolute',
        width: '100%',
        height: panelHeight || '100%',
        bottom: 0
      };
    }

    return {
      top: 0,
      height: panelHeight || '100%'
    };
  }, [small, isPortrait, panelHeight]);

  return (
    <Delayed isMounted={panelLiveOption.show} shouldDelay delay={TRANSITION_DURATION}>
      {() => (
        <div
          style={{
            ...setStyles(PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED),
            ...panelWrapperStyle,
            animationTimingFunction: 'ease-in-out',
            animation: panelLiveOption.show
              ? `ftv-magneto-panel-live-option-show${(isPortrait && '-portrait') || ''} ${TRANSITION_DURATION}ms`
              : `ftv-magneto-panel-live-option-hide${(isPortrait && '-portrait') || ''} ${TRANSITION_DURATION}ms`
          }}
          id="panel-live-option"
        >
          <Button
            type="icon"
            openingMode="click"
            label="fermer"
            ariaLabel="fermer"
            forceLabel
            disableFocus={false}
            last
            onClick={() => onClose(false)}
            onKeyDown={(e) => {
              if (isValidateKeyboard(e)) {
                onClose(true);
              }
            }}
            icon="close"
            xs
            iconRef={null}
            name="btn-close-panel-live-options"
            ariaHidden={false}
            size={setStyles(PANEL_CLOSE_BUTTON_SIZE).width}
            zone="top"
            styleOverride={setStyles(PANEL_CLOSE_BUTTON)}
            labelStyle={setStyles(PANEL_CLOSE_LABEL)}
          />
          <div
            id="panel"
            style={{
              height: (!small && panelHeight) || '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div style={setStyles(PANEL_TITLE)}>
              {
                TITLES_MAPPING[panelLiveOption.currentTab]
                || TITLES_MAPPING[ZAPPING_NAME]
              }
            </div>
            {panelLiveOption.currentTab === CHAT_NAME
              ? (<Chat />)
              : (
                <Fragment>
                  <ProgramTypeSwitch />
                  <Programs />
                </Fragment>
              )}
          </div>
        </div>
      )}
    </Delayed>
  );
}

export default withBreakPoints(PanelLiveOption);
