/**
 * Hack utility to check wether a font is available on the current page:
 * Knowing that monospace font characters are all of equal width :
 * - Draw a sample text with the font to be tested on a canvas with a fallback to mono
 * - Draw the same text with the default monospace font
 * - Measure both text samples :
 *    -> if they match: it means we fellback on mono so font isn't available
 *    -> if they don't: it means font is available
 * NOTE: FontAPI is coming to the browser sometime in the 21st century..
 */

const BASE_TEXT = 'abcdefghijklmnopqrstuvwxyz0123456789';

export function measureFont({ ctx, fontName }) {
  ctx.font = `72px ${fontName}`;
  return ctx.measureText(BASE_TEXT).width;
}

export function isFontAvailabe(fontName) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return measureFont({ ctx, fontName: `${fontName}, monospace` })
    !== measureFont({ ctx, fontName: 'monospace' });
}

export function addFont({
  name,
  base64,
  fontWeight,
  format
}) {
  const css = `@font-face { \
      font-family: ${name}; \
      font-weight: ${fontWeight}; \
      src: url(${base64}) format('${format}');\
    }
  `;
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));

  document.head.appendChild(style);
}
