import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { Button } from '../../common';
import { ZAP_ICON_SIZE, ZAP_CONTENT, ZAP_WRAPPER } from './styles';
import { BUTTON_LAYER } from '../../../theme/constants';
import { USER_CLICK, USER_REMOTE_CHAT_CLICKED, USER_REMOTE_HIGHLIGHTS_CLICKED, USER_REMOTE_ZAPPING_CLICKED } from '../../../../types';
import { connect, withBreakPoints } from '../../../hoc';
import { TOGGLE_SHOW_PANNEL_LIVE_OPTION, UI_TOGGLE_HOVER_LABEL } from '../../../../store/types';
import { CHAT_NAME, EMPTY_ACTIVE_NAME, HIGHLIGHTS_NAME, ZAPPING_NAME } from './constants';

const handleClick = ({ player, setActive, isActive, name, action }) => {
  setActive(isActive ? EMPTY_ACTIVE_NAME : name);
  player.store.dispatch({
    type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
    payload: {
      show: !isActive,
      currentTab: isActive ? EMPTY_ACTIVE_NAME : name
    }
  });
  player.store.dispatch({ type: UI_TOGGLE_HOVER_LABEL, payload: { showHover: false } });
  player.userEvents$.next({
    action,
    value: !isActive,
    source: USER_CLICK
  });
};

function Zap({ show, zappingEnabled, hasHighlight, hasChat, activeCurrentTab }) {
  const [currentTab, setActive] = useState(activeCurrentTab);
  const { size } = useStyles()(ZAP_ICON_SIZE);

  /* Required for close button to reset state */
  useEffect(
    () => currentTab !== activeCurrentTab && setActive(activeCurrentTab),
    [activeCurrentTab]
  );

  const isZappingActive = currentTab === ZAPPING_NAME;
  const isHighlightsActive = currentTab === HIGHLIGHTS_NAME;
  const isChatActive = currentTab === CHAT_NAME;
  const noTranslateY = { transform: 'translateY(0)' };

  const player = usePlayerContext();
  return show && (
    <div id="zapping" style={ZAP_WRAPPER}>
      <div style={useStyles()(ZAP_CONTENT)}>
        { zappingEnabled && (
        <Button
          label="tous les directs"
          ariaLabel={`${isZappingActive ? 'fermer' : 'ouvrir'} la zapette`}
          ariaHidden="false"
          onClick={() => {
            handleClick({
              player,
              setActive,
              isActive: isZappingActive,
              type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
              name: ZAPPING_NAME,
              action: USER_REMOTE_ZAPPING_CLICKED
            });
          }}
          xs
          name="btn-remote-controller"
          disableFocus={false}
          zIndex={BUTTON_LAYER + 1}
          type="icon"
          icon={isZappingActive ? 'remoteControlActive' : 'remoteControl'}
          size={size}
          zone="panel"
          labelStyle={noTranslateY}
        />
        )}

        {hasHighlight && (
        <Button
          label="moments forts"
          ariaLabel={`${isHighlightsActive ? 'fermer' : 'ouvrir'} le panel des moments forts`}
          ariaHidden="false"
          onClick={() => {
            handleClick({
              player,
              setActive,
              isActive: isHighlightsActive,
              type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
              name: HIGHLIGHTS_NAME,
              action: USER_REMOTE_HIGHLIGHTS_CLICKED
            });
          }}
          xs
          name="btn-highlight"
          disableFocus={false}
          zIndex={BUTTON_LAYER + 1}
          type="icon"
          icon={!isHighlightsActive ? 'flag' : 'flagActive'}
          size={size}
          zone="panel"
          labelStyle={noTranslateY}
        />
        )}

        {hasChat && (
        <Button
          label="live chat"
          ariaLabel={`${isChatActive ? 'ouvrir' : 'fermer'} le chat`}
          ariaHidden="false"
          onClick={() => {
            handleClick({
              player,
              setActive,
              isActive: isChatActive,
              type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
              name: CHAT_NAME,
              action: USER_REMOTE_CHAT_CLICKED
            });
          }}
          xs
          name="btn-chat"
          disableFocus={false}
          zIndex={BUTTON_LAYER + 1}
          type="icon"
          icon={!isChatActive ? 'chat' : 'chatActive'}
          size={size}
          zone="panel"
          labelStyle={noTranslateY}
        />
        )}
      </div>
    </div>
  );
}

const selector = ({
  zapping: { list, zappingEnabled = list.length > 0 },
  ui: {
    chatAvailable, waitStart, isExtraSmallScreen, isSmallScreen, isFullscreen, panelLiveOption: { currentTab: activeCurrentTab },
    hasChat = chatAvailable && isFullscreen && !isSmallScreen && !isExtraSmallScreen
  },
  highlights: { pinsPosition, hasHighlight = !!pinsPosition.length },
  media: { isAd, isDAI },
  ad: { adType }
}) => ({
  show: (!isAd || (isAd && adType !== 'PREROLL' && isDAI)) && !isExtraSmallScreen && !waitStart && (zappingEnabled || hasHighlight || hasChat),
  zappingEnabled,
  hasHighlight,
  hasChat,
  activeCurrentTab
});
export default withBreakPoints(connect(selector)(Zap));
